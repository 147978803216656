import { CustomerTag } from "@farmact/model/src/model/CustomerTag";
import { Employee } from "@farmact/model/src/model/Employee";
import { GasStation } from "@farmact/model/src/model/GasStation";
import { LoadingOrUnloadingPoint } from "@farmact/model/src/model/LoadingOrUnloadingPoint";
import { Machine } from "@farmact/model/src/model/Machine";
import { Resource } from "@farmact/model/src/model/Resource";
import { ResourceTag } from "@farmact/model/src/model/ResourceTag";
import { Service } from "@farmact/model/src/model/services/Service";
import { Supplier } from "@farmact/model/src/model/Supplier";
import { useContext } from "react";
import { OrganizationContext } from "./OrganizationContext";

export function useOrganizationContext() {
    return useContext(OrganizationContext);
}

export type OrganizationContextType = {
    machines: Machine[];
    services: Service[];
    resources: Resource[];
    gasStations: GasStation[];
    employees: Employee[];
    resourceTags: ResourceTag[];
    suppliers: Supplier[];
    loadingOrUnloadingPoints: LoadingOrUnloadingPoint[];
    customerTags: CustomerTag[];
    //
    resourceTagsLoading: boolean;
    machinesLoading: boolean;
    servicesLoading: boolean;
    resourcesLoading: boolean;
    gasStationsLoading: boolean;
    employeesLoading: boolean;
    loadingPointsLoading: boolean;
    customerTagsLoading: boolean;
    suppliersLoading: boolean;
};
