import { ReactNode } from "react";
import "./rentalOrderPriceTrackingFormList.scss";

type RentalOrderPriceTrackingFormListProps = {
    children: ReactNode;
};

export function RentalOrderPriceTrackingFormList(props: RentalOrderPriceTrackingFormListProps) {
    return <div className="rental-order-price-tracking-form-list">{props.children}</div>;
}
