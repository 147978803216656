import { faArrowDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import { ComponentPropsWithoutRef } from "react";
import "./rentalOrderPriceTrackingFormSubtitle.scss";

type RentalOrderPriceTrackingFormSubtitleProps = ComponentPropsWithoutRef<"h3">;

export function RentalOrderPriceTrackingFormSubtitle(props: RentalOrderPriceTrackingFormSubtitleProps) {
    return (
        <h3 {...props} className={clsx("rental-order-price-tracking-form-subtitle", props.className)}>
            <FontAwesomeIcon icon={faArrowDown} />
            {props.children}

            <div className="rental-order-price-tracking-form-subtitle__line" />
        </h3>
    );
}
