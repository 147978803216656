import { ReactNode } from "react";
import "./rentalOrderPriceTrackingFormRoot.scss";

type RentalOrderPriceTrackingFormRootProps = {
    children: ReactNode;
};

export function RentalOrderPriceTrackingFormRoot(props: RentalOrderPriceTrackingFormRootProps) {
    return <div className="rental-order-price-tracking-form-root">{props.children}</div>;
}
