import { Context, createContext } from "react";
import { OrganizationContextType } from "./useOrganizationContext";

export const OrganizationContext: Context<OrganizationContextType> = createContext<OrganizationContextType>({
    machines: [],
    services: [],
    resources: [],
    gasStations: [],
    employees: [],
    loadingOrUnloadingPoints: [],
    resourceTags: [],
    customerTags: [],
    suppliers: [],
    resourceTagsLoading: false,
    machinesLoading: false,
    servicesLoading: false,
    resourcesLoading: false,
    gasStationsLoading: false,
    employeesLoading: false,
    loadingPointsLoading: false,
    customerTagsLoading: false,
    suppliersLoading: false,
});

export default OrganizationContext;
